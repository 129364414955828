import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";

interface ServicesData {
  icon: string;
  title: any;
  description: any;
}

const servicesData: ServicesData[] = [
  {
    icon: "pe-7s-graph2",
    title: {
      en: "Business Consulting and Development",
      fa: "مشاوره و توسعه کسب‌وکار",
    },
    description: {
      en: "We provide expert consulting to help grow and develop your business, ensuring a competitive edge in the market.",
      fa: "ما مشاوره‌های تخصصی برای رشد و توسعه کسب‌وکار شما ارائه می‌دهیم تا در بازار رقابتی پیشرو باشید.",
    },
  },
  {
    icon: "pe-7s-tools",
    title: {
      en: "Business and Venture Setup",
      fa: "راه‌اندازی کسب‌وکار و مجموعه‌های تجاری",
    },
    description: {
      en: "From idea to execution, we guide you in setting up successful business ventures and commercial entities.",
      fa: "از ایده تا اجرا، ما در راه‌اندازی کسب‌وکارهای موفق و مجموعه‌های تجاری شما را همراهی می‌کنیم.",
    },
  },
  {
    icon: "pe-7s-date",
    title: {
      en: "Event Planning and Execution",
      fa: "برنامه‌ریزی و اجرای ایونت‌ها",
    },
    description: {
      en: "We plan and execute impactful events tailored to your goals, ensuring a memorable experience for your audience.",
      fa: "ما ایونت‌هایی منطبق با اهداف شما برنامه‌ریزی و اجرا می‌کنیم تا تجربه‌ای به‌یادماندنی ایجاد شود.",
    },
  },
  {
    icon: "pe-7s-study",
    title: { en: "Training and Competitions", fa: "آموزش و برگزاری مسابقات" },
    description: {
      en: "We organize training sessions and competitions to foster growth and development in your field of interest.",
      fa: "ما جلسات آموزشی و مسابقات را برای رشد و توسعه در زمینه مورد علاقه شما برگزار می‌کنیم.",
    },
  },
  {
    icon: "pe-7s-global",
    title: {
      en: "Digital Services",
      fa: "خدمات دیجیتال و حضور آنلاین",
    },
    description: {
      en: "Strengthen your digital presence with our comprehensive services, including web development, social media management and branding.",
      fa: "حضور دیجیتال خود را با خدمات جامع ما از جمله توسعه وب، شبکه‌های اجتماعی و برندینگ تقویت کنید.",
    },
  },
  {
    icon: "pe-7s-cash",
    title: {
      en: "Financial, Accounting, and Management Services",
      fa: "خدمات مالی، حسابداری و مدیریت مالی",
    },
    description: {
      en: "We offer financial and accounting solutions to ensure effective management for your business.",
      fa: "ما راه‌حل‌های مالی و حسابداری برای مدیریت مؤثر و تصمیم‌گیری استراتژیک کسب‌وکار شما ارائه می‌دهیم.",
    },
  },
];

const Service = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <React.Fragment>
      {/* Services */}
      <section className="section service" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("service-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  {t("service-desc")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 g-4">
            {(servicesData || []).map((item: ServicesData, index: number) => (
              <Col
                key={index}
                lg={4}
                md={6}
                sm={12}
                className="d-flex align-items-stretch"
              >
                <div className="agency-services-box text-center box-border d-flex flex-column w-100 justify-content-center align-items-center">
                  <div className="agency-services-icon mb-3">
                    <i className={item.icon}></i>
                  </div>
                  <h5 className="service-item-header text-uppercase mb-2">
                    {language === "fa" ? item.title.fa : item.title.en}
                  </h5>
                  <p className="heading-title-desc text-muted">
                    {language === "fa"
                      ? item.description.fa
                      : item.description.en}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <hr />
    </React.Fragment>
  );
};

export default Service;
